import { qspost } from '@/config/axios';

//获取数据
export const getList = data => qspost("request?", data, "getVipList");

//编辑数据
export const edit = data => qspost("request?", data, "updateVip");

//开关促销
export const switchEnable = data => qspost("request?", data, "changeEnable");

//开关启动状态
export const switchDisable = data => qspost("request?", data, "updateDisable");