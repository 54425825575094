<template>
  <div id="bg">
    <div class="search">
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="vip_name" label="VIP名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="expire_day" label="VIP有效期" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="card_type" label="卡片类型" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div>
<!--             scope.row.card_type== 1 ? "蓝色" : scope.row.card_type == 2 ? "紫色" : scope.row.card_type == 3 ? "红色" : scope.row.card_type == 4 ? "金色" : "类型错误" }-->
            {{showCardType(scope.row.card_type)}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="download" label="下载次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="short_video_times" label="短视频次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="展示权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="original_price" label="原价" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="price" label="实际价格" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="return_price" label="返利金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="buy_videos" label="长视频折扣" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="buy_short_videos" label="短视频折扣" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="enable" label="促销倒计时" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch v-model="scope.row.enable" :active-value=1 :inactive-value=0 @change="switchEnable(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column prop="view_disable" label="启用" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch v-model="scope.row.view_disable" :active-value=1 :inactive-value=0 @change="switchDisable(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="setEdit(scope.row)">编辑</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加编辑 -->
    <el-dialog :title="basisName" v-model="basisShow" width="650px" top="35vh" :close-on-click-modal="false" @close="emptyBasis">
      <div class="form_box">
        <div class="form_title">VIP名称：</div>
        <el-input v-model="basisArr.vip_name" placeholder="VIP名称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">卡片类型：</div>
        <el-select v-model="basisArr.card_type" filterable placeholder="全部" size="medium" clearable>
          <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="form_box">
        <div class="form_title">有效期：</div>
        <el-input v-model="basisArr.expire_day" placeholder="有效期" size="small" type="number"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">下载次数：</div>
        <el-input v-model="basisArr.download" placeholder="下载次数" size="small" type="number"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">短视频次数：</div>
        <el-input v-model="basisArr.short_video_times" placeholder="短视频次数" size="small" type="number"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">展示权重：</div>
        <el-input v-model="basisArr.weight" placeholder="展示权重" size="small" type="number"></el-input>
      </div>
      <div class="form_box2">
        <div class="form_title">价格：</div>
        <el-input v-model="basisArr.original_price" placeholder="价格" size="small" type="number"></el-input>
        <div class="form_title">折后价：</div>
        <el-input v-model="basisArr.price" placeholder="折后价" size="small" type="number"></el-input>
        <div class="red">*{{ ((basisArr.price / basisArr.original_price)*10).toFixed(2) || 'x'}}折</div>
      </div>
      <div class="form_box">
        <div class="form_title">返利金额：</div>
        <el-input v-model="basisArr.return_price" placeholder="返利金额" size="small" type="number"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">长视频折扣：</div>
        <el-input v-model="basisArr.buy_videos" placeholder="长视频折扣" size="small" type="number"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">短视频折扣：</div>
        <el-input v-model="basisArr.buy_short_videos" placeholder="短视频折扣" size="small" type="number"></el-input>
      </div>
      <div class="form_box2">
        <div class="form_title">促销倒计时：</div>
        <el-switch v-model="basisArr.enable" :active-value=1 :inactive-value=0 />
        <div class="form_title">启用：</div>
        <el-switch v-model="basisArr.view_disable" :active-value=0 :inactive-value=1 />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="basisName == '添加VIP'">确认添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getList, edit, switchEnable, switchDisable } from "@/api/vip/set";
export default {
  name: "videoClass",
  components: {},
  data() {
    return {
      tableData: [{}], // 数据
      row: {}, // 选中的数据
      typeArr: [
        { value: 1, label: "蓝色" },
        { value: 2, label: "紫色" },
        { value: 3, label: "红色" },
        { value: 4, label: "金色" },
        { value: 5, label: "黑金" },
      ],
      basisName: "", // 弹窗标题
      basisShow: false, // 添加弹窗
      basisArr: {
        vip_name: "", // vip名称
        card_type: 0, // vip类型
        expire_day: 0, // 有效期 天
        weight: 0, // 权重
        download: 0, // 下载次数
        original_price: 0, // 原价
        price: 0, // 实际价格
        enable: 0, // 促销开关 0关闭 1开启
        view_disable: 0, // 启动开关 0开启 1关闭
        return_price: 0,// 返利金额
        short_video_times: 0,
        buy_short_videos:0,
        buy_videos:0,
      }, // 基础数据
      switch: false, // 开关
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    showCardType(id){
      for(var i in this.typeArr){
        if (this.typeArr[i].value == id){
          return this.typeArr[i].label
        }
      }
      return "未知类型";
    },
    //获取数据
    getList() {
      getList()
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
            this.switch = true;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //把数据放入编辑内
    setEdit(row) {
      this.row = row;
      this.basisName = `编辑VIP(ID：${row.id})`;
      this.basisArr = {
        vip_name: row.vip_name,
        card_type: row.card_type,
        expire_day: row.expire_day,
        weight: row.weight,
        download: row.download,
        original_price: row.original_price,
        price: row.price,
        enable: row.enable,
        view_disable: row.view_disable,
        return_price: row.return_price,
        short_video_times: row.short_video_times,
        buy_videos: row.buy_videos,
        buy_short_videos: row.buy_short_videos,
      };
      this.basisShow = true;
    },
    //编辑数据
    edit() {
      const basisArr = this.basisArr;
      if(basisArr.return_price < 0) this.$message({ message: "返利金额不能为负数", type: "error" });
      edit([
        { key: "id", val: this.row.id },
        { key: "vip_name", val: basisArr.vip_name },
        { key: "expire_day", val: basisArr.expire_day },
        { key: "card_type", val: basisArr.card_type },
        { key: "weight", val: basisArr.weight },
        { key: "download", val: basisArr.download },
        { key: "original_price", val: basisArr.original_price },
        { key: "price", val: basisArr.price },
        { key: "enable", val: basisArr.enable },
        { key: "disable", val: basisArr.view_disable },
        { key: "return_price", val: basisArr.return_price },
        { key: "short_video_times", val: basisArr.short_video_times },
        { key: "buy_short_videos", val: basisArr.buy_short_videos },
        { key: "buy_videos", val: basisArr.buy_videos },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.basisShow = false;
            this.getList();
            this.$message({ message: res.data, type: "success" });
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 清空基础数据
    emptyBasis() {
      this.basisArr = {
        vip_name: "",
        card_type: 0,
        expire_day: 0,
        weight: 0,
        download: 0,
        original_price: 0,
        price: 0,
        enable: 0,
        view_disable: 0,
        return_price: 0,
        short_video_times: 0,
      };
      this.row = {};
    },
    // 开关促销
    switchEnable(row) {
      if (!this.switch) return;
      const data = [{ key: "id", val: row.id },{ key: "enable", val: row.enable }];
      switchEnable(data).then((res) => {
        if (res.code == 0) {
          this.getList();
          this.$message({ message: res.data, type: "success" });
        } else {
          this.$message({ message: res.data, type: "error" });
        }
      });
    },
    // 开关启动状态
    switchDisable(row) {
      if (!this.switch) return;
      const data = [{ key: "id", val: row.id },{ key: "disable", val: row.view_disable }];
      switchDisable(data).then((res) => {
        if (res.code == 0) {
          this.getList();
          this.$message({ message: res.data, type: "success" });
        } else {
          this.$message({ message: res.data, type: "error" });
        }
      });
    },
  },
};
</script>

<style scoped>
.form_box,
.form_box2 {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 15%;
  float: left;
  height: 32px;
  line-height: 32px;
  text-align: right;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 75%;
}
.form_box2 >>> .el-input {
  width: 25%;
  float: left;
}
.form_box2 >>> .red {
  display: inline-block;
  color: red;
  margin-left: 10px;
}
.form_box2 >>> .el-switch {
  float: left;
  margin-top: 6px;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>